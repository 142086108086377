import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

const EMISSION_RANGES = [
  {min: 0, max: 0, letter: 'a' as const},
  {min: 1, max: 95, letter: 'b' as const},
  {min: 96, max: 115, letter: 'c' as const},
  {min: 116, max: 135, letter: 'd' as const},
  {min: 136, max: 155, letter: 'e' as const},
  {min: 156, max: 175, letter: 'f' as const},
  {min: 176, max: Infinity, letter: 'g' as const},
];

export const getBadgeLetter = (emissionValue: string | number | Nullish) => {
  if (isNilOrEmpty(emissionValue)) {
    return null;
  }
  const emissionNumber = Number(emissionValue);
  if (Number.isNaN(emissionNumber)) {
    return null;
  }

  const range = EMISSION_RANGES.find((r) => emissionNumber >= r.min && emissionNumber <= r.max);

  return range ? range.letter : null;
};
