import styled, {useTheme} from 'styled-components';

interface BadgeProps {
  variant: 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g';
}

// eslint-disable-next-line eag/no-css-property
const StyledBadge = styled.div<{$color: string}>`
  width: ${({theme}) => theme.getSize(5)};
  height: ${({theme}) => theme.getSize(6)};
  background-color: ${({$color}) => $color};
  position: relative;
  border-top-left-radius: ${({theme}) => theme.radii.xSmall};
  border-bottom-left-radius: ${({theme}) => theme.radii.xSmall};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  text-transform: uppercase;
  padding-left: 6px;
`;

export function Badge(props: BadgeProps) {
  const theme = useTheme();
  const color = theme.components.Badge[props.variant];
  return (
    <StyledBadge $color={color}>
      {props.variant}
      <svg
        css={{position: 'absolute', right: '-9px'}}
        width="9"
        height="24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.191 9.003 3.163 1.717C2.416.634 1.233.002 0 .002v23.996c1.336 0 2.91-.745 3.64-1.98l4.718-7.986c.914-1.547.847-3.56-.167-5.029Z" />
      </svg>
    </StyledBadge>
  );
}
