import {Spinner, HStack, Image, Box} from 'platform/foundation';

export function Loading() {
  return (
    <HStack align="center" justify="center" minHeight="100vh" minWidth="100vw">
      <Box padding={3}>
        <HStack spacing={4} align="center" justify="center">
          <Image src="./assets/images/logo.svg" alt="Digital Deal" height={12} width="auto" />
          <Spinner size="large" />
        </HStack>
      </Box>
    </HStack>
  );
}
