import {useParams} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {
  AlphaCatalogue,
  useGetAllApiEnumsQuery,
  useGetVehicleAuditQuery,
  VehicleAudit,
} from '../api/digitalCertificateApi';
import {useGetBusinessCaseOnlineOfferQuery} from '../api/onlineOfferApi';
import {BusinessCaseOnlineOfferResponseBody} from '../api/types/onlineOfferTypes';
import {queryParams} from '../consts/queryParams';
import {i18n} from '../i18n/i18n';
import {getWorkspaceFromUri} from '../utils/getWorkspaceFromUri';
import {useQueryState} from '../utils/useQueryState';

const {workspace} = getWorkspaceFromUri();

type GetOnlineOfferDataContext = Partial<BusinessCaseOnlineOfferResponseBody> & {
  alphaCatalogue: AlphaCatalogue | undefined;
  vehicleAudit: VehicleAudit | Nullish;
};

export function useGetOnlineOfferData(): GetOnlineOfferDataContext {
  const [language] = useQueryState(queryParams.LNG);
  const params = useParams<{offerId: string}>();

  const {data: onlineOfferData} = useGetBusinessCaseOnlineOfferQuery({
    offerId: params.offerId ?? '',
    workspace: workspace ?? '',
  });

  const {data: alphaCatalogue} = useGetAllApiEnumsQuery({lang: language ?? i18n.resolvedLanguage});

  const {data: vehicleAudit} = useGetVehicleAuditQuery(
    {auditId: onlineOfferData?.carAuditId ?? '', workspace: workspace ?? ''},
    {skip: isNilOrEmpty(onlineOfferData?.carAuditId)}
  );

  return {...onlineOfferData, alphaCatalogue, vehicleAudit};
}
