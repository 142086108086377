import {SectionNew} from '../../components/SectionNew/SectionNew';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {getRatingCardParams} from '../Hero/utils/getRatingCardParams';
import {CertificateBadge} from './CertificateBadge';

export const Certificate = () => {
  const {vehicleAudit} = useGetOnlineOfferData();
  const audit = vehicleAudit?.audit;
  const ratings = getRatingCardParams(vehicleAudit);
  if (!audit && !ratings) {
    return;
  }
  return (
    <SectionNew id="CERTIFICATE" heading="Stav vozu" isWithBreakLine>
      <CertificateBadge
        title="Certifikát stavu vozu"
        digitalCertificateVehicleId={audit?.id}
        ratings={ratings}
      />
    </SectionNew>
  );
};
