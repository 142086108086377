/**
 * To avoid merge conflicts, please add new params to a random location (not the last line).
 */
export enum queryParams {
  LNG = 'lang',
  SELLER_EMAIL = 'sellerEmail',
}
/**
 * To avoid merge conflicts, please add new params to a random location (not the last line).
 */
