import {Box, Display, Image, Link, Show, Text, VStack} from 'platform/foundation';
import {z} from 'zod';

import {useState} from 'react';

import {Button} from '../../components/Button/Button';
import {queryParams} from '../../consts/queryParams';
import {useQueryState} from '../../utils/useQueryState';

export function NotFoundPage() {
  const [sellerEmail] = useQueryState(queryParams.SELLER_EMAIL);
  const emailSchema = z.string().email();
  const isValidEmail = emailSchema.safeParse(sellerEmail);
  const [isButtonOpened, setIsButtonOpened] = useState(false);

  return (
    <VStack height="100vh" justify="center" align="center">
      <VStack spacing={8} width="100%" align="center" maxWidth={130}>
        <Image
          src="./assets/images/car-not-found.svg"
          alt="Digital Deal not found illustration"
          height={35}
          width="auto"
          isLazy
        />
        <Box paddingHorizontal={6}>
          <VStack spacing={6} justify="center" align="center">
            <Display size={1}>Nabídka již není platná</Display>
            <Text align="center">
              Pravděpodobně byl nabízený vůz odebrán z obchodního případu, nebo byla transakce
              uzavřena jako neúspěšná.
            </Text>
            <Show when={isValidEmail.success}>
              <Button
                variant="secondary"
                title="Kontaktovat prodejce"
                onClick={() => {
                  setIsButtonOpened(true);
                  window.location.href = `mailto:${isValidEmail.data}`;
                }}
              />
              <Show when={isButtonOpened}>
                <VStack justify="center" align="center">
                  <Text align="center">
                    Pokud se váš e-mailový program neotevřel po kliknutí na tlačítko, můžete využít
                    níže uvedenou e-mailovou adresu:
                  </Text>
                  <Link href={`mailto:${isValidEmail.data}`} title={isValidEmail.data} />
                </VStack>
              </Show>
            </Show>
          </VStack>
        </Box>
      </VStack>
    </VStack>
  );
}
